import React from 'react'

export const Table = ({ node }) => (
  <div>
    {node.columns.map(col => (
      <dl key={col._key} className="flex py-4 last:border-0 border-b text-body">
        <dt className="flex-1">{col.title}</dt>
        <dd className="md:flex-1">{col.text}</dd>
      </dl>
    ))}
  </div>
)
