import React, { useRef, useState, useEffect } from 'react'
import cx from 'clsx'
import Image from 'gatsby-plugin-sanity-image'
import { urlFor } from '../utils/sanity'
import { useKeenSlider } from 'keen-slider/react'
import { ArrowLeft, ArrowRight } from '../components/Icons'

const ArrowSlider = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderRef, slider] = useKeenSlider({
    slides: '.slider-slide',
    spacing: 20,
    loop: true,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
  })

  return (
    <div className="slider-container">
      <div ref={sliderRef} className="slider">
        <div className="absolute top-0 right-0 z-10 p-2.5 pointer-events-none md:right-20 md:p-5">
          <img className="w-20 h-20 md:w-32 md:h-32" src={'/stamp.svg'} alt=''/>
        </div>

        {data?.images?.map(image => (
          <div key={image._key} className="slider-slide md:px-20">
            <div className="aspect-w-6 aspect-h-4">
              <Image {...image} alt={image.alt || ''} />
            </div>
          </div>
        ))}
      </div>

      {slider && (
        <>
          <button
            aria-label="Scroll Prev"
            className="arrow arrow-prev arrow-secondary ml-2"
            onClick={e => e.stopPropagation() || slider.prev()}
          >
            <ArrowLeft className="w-5 h-5 md:w-10 md:h-10" />
          </button>

          <button
            aria-label="Scroll Next"
            className="arrow arrow-next arrow-secondary mr-2"
            onClick={e => e.stopPropagation() || slider.next()}
          >
            <ArrowRight className="w-5 h-5 md:w-10 md:h-10" />
          </button>
        </>
      )}

      {slider && (
        <div className="slider-dots">
          {[...Array(slider.details().size).keys()].map(index => (
            <button
              key={index}
              onClick={() => slider.moveToSlideRelative(index)}
              aria-label={`Navigate to slide ${index + 1}`}
              className={cx('slider-dot', {
                'is-selected': currentSlide === index,
              })}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const AutoplaySlider = ({ data }) => {
  const [sliderRef, slider] = useKeenSlider({
    slides: '.slider-slide',
    slidesPerView: 1,
    controls: false,
    loop: true,
    duration: 0,
  })

  const timer = useRef()

  useEffect(() => {
    timer.current = setInterval(() => {
      if (slider) {
        slider.next()
      }
    }, 1500)
    return () => {
      clearInterval(timer.current)
    }
  }, [slider])

  return (
    <div className="slider-container">
      <div ref={sliderRef} className="slider">
        {data?.images?.map(image => (
          <div
            key={image._key}
            className="slider-slide flex-grow-0 flex-shrink-0"
          >
            <div className="aspect-w-6 aspect-h-4 md:aspect-w-5 md:aspect-h-2">
              <img
                src={urlFor(image)
                  .width(1024)
                  .quality(75)
                  .fit('max')
                  .auto('format')
                  .url()}
                alt={image.alt || ''}
                className="object-contain"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const Slider = ({ node }) => {
  return node.mode === 'arrows' ? (
    <ArrowSlider data={node} />
  ) : (
    <AutoplaySlider data={node} />
  )
}
