import React from 'react'

export const Link = ({ node }) => {
  const { title, link, blank } = node

  return blank ? (
    <a href={link} target="_blank" rel="noopener noreferrer" className="link">
      {title}
    </a>
  ) : (
    <a href={link} className="link">
      {title}
    </a>
  )
}
