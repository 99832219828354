import React from 'react'
import { urlFor } from '../utils/sanity'

export const Video = ({ node }) => {
	const { placeholder } = node
	const posterUrl = urlFor(placeholder).auto('format').url()

	// const containerStyle = {
	// 	backgroundImage: `url('${posterUrl}')`,
	// 	backgroundSize: 'cover',
	// 	backgroundPosition: 'center bottom',
	// 	backgroundRepeat: 'no-repeat',
	// }

	// useEffect(() => {
	// 	const checkLoad = () => {
	// 		setLoaded(true)
	// 	}

	// 	if (videoRef && videoRef.current) {
	// 		videoRef.current.onloadeddata = () => checkLoad()
	// 		if (videoRef.current.readyState >= 3) checkLoad()
	// 		videoRef.current.play()
	// 		return () => videoRef.current.removeEventListener('loadeddata', checkLoad)
	// 	}
	// }, [videoRef])

	return (
		<div className='aspect-w-16 aspect-h-9'>
			<video
				className=''
				poster={posterUrl}
				allow='autoplay'
				defaultmuted='true'
				muted
				autoPlay
				loop
				playsInline
			>
				<source
					src='https://player.vimeo.com/progressive_redirect/playback/768772685/rendition/540p/file.mp4?loc=external&signature=d239f63a8000e2044afbdbdf0f70359bd1c5faa4e43b4811e72e9f199687f7a8'
					type='video/mp4'
				/>
			</video>
		</div>
	)
}
