import React from 'react'
import Image from 'gatsby-plugin-sanity-image'

export const FigureStamp = ({ node }) => (
  <div className="relative">
    <div className="absolute top-0 right-0 z-10 p-2.5 pointer-events-none md:p-5">
      <img className="w-20 h-20 md:w-32 md:h-32" src={'/stamp.svg'} alt=''/>
    </div>
    <div className="aspect-w-6 aspect-h-4">
      {node.asset && (
        <Image {...node} width={1024} alt={node.alt || ''} className="object-cover" />
      )}
    </div>
  </div>
)
