
import React from 'react'
import Image from 'gatsby-plugin-sanity-image'
import { useImageSize } from '../hooks/useImageSize'

export const Figure = ({ node }) => {
	const imageSize = useImageSize(0.5333)
	const size = node?.options?.width
	const styles = {
		small: 'w-full md:w-1/2 mx-auto',
		medium: 'w-full md:w-3/4 mx-auto',
		fullWidth: 'w-full h-auto',
	}
	return (
		<div className={styles[size]}>
			{node.asset && (
				<Image {...node} width={imageSize} alt={node.alt} />
			)}
		</div>
	)
}